export const modules = {
  WxuHeaderMobile: {
    clientLoader: () => import(/* webpackChunkName: "HeaderMobile" */'@wxu/modules/src/HeaderMobile/client.loader').then(module => module.clientLoader),
  },
  WxuContentMedia: {
    clientLoader: () => import(/* webpackChunkName: "ContentMedia" */'@wxu/modules/src/ContentMedia/client.loader').then(module => module.clientLoader),
  },
  WxuAirQuality: {
    clientLoader: () => import(/* webpackChunkName: "AirQuality" */'@wxu/modules/src/AirQuality/client.loader').then(module => module.clientLoader),
  },
  WxuHealthAndActivities: {
    clientLoader: () => import(/* webpackChunkName: "HealthActivitiesCard" */'@wxu/modules/src/HealthActivitiesCard/client.loader').then(module => module.clientLoader),
  },
  WxuAd: {
    clientLoader: () => import(/* webpackChunkName: "Money" */'@wxu/modules/src/Ad/client.loader').then(module => module.clientLoader),
  },
  Taboola: {
    clientLoader: () => import(/* webpackChunkName: "Taboola" */'@wxu/modules/src/Taboola/client.loader').then(module => module.clientLoader),
  },
  Outbrain: {
    clientLoader: () => import(/* webpackChunkName: "Outbrain" */'@wxu/modules/src/Outbrain/client.loader').then(module => module.clientLoader),
  },
  WxuDaybreakMetrics: {
    clientLoader: () => import(/* webpackChunkName: "DaybreakMetrics" */'@wxu/modules/src/DaybreakMetrics/client.loader').then(module => module.clientLoader),
  },
  WxuNavigation: {
    clientLoader: () => import(/* webpackChunkName: "Navigation" */'@wxu/modules/src/Navigation/client.loader').then(module => module.clientLoader),
  },
  WxuUpsellCard: {
    clientLoader: () => import(/* webpackChunkName: "UpsellCard" */'@wxu/modules/src/UpsellCard/client.loader').then(module => module.clientLoader),
  },
  WxuUpsellToast: {
    clientLoader: () => import(/* webpackChunkName: "UpsellToastMessage" */'@wxu/modules/src/UpsellToastMessage/client.loader').then(module => module.clientLoader),
  },
  WxuUpsellOverlay: {
    clientLoader: () => import(/* webpackChunkName: "UpsellOverlay" */'@wxu/modules/src/UpsellOverlay/client.loader').then(module => module.clientLoader),
  },
  WxuNewsroom: {
    clientLoader: () => import(/* webpackChunkName: "Newsroom" */'@wxu/modules/src/Newsroom/client.loader').then(module => module.clientLoader),
  },
  WxuHourlyCard: {
    clientLoader: () => import(/* webpackChunkName: "HourlyCard" */'@wxu/modules/src/HourlyForecast/client.loader').then(module => module.clientLoader),
  },
  WxuPrivacyDataNotice: {
    clientLoader: () => import(/* webpackChunkName: "PrivacyDataNotice" */'@wxu/modules/src/PrivacyDataNotice/client.loader').then(module => module.clientLoader),
  },
  WxuPrivacyFeedCard: {
    clientLoader: () => import(/* webpackChunkName: "PrivacyFeedCard" */'@wxu/modules/src/PrivacyFeedCard/client.loader').then(module => module.clientLoader),
  },
  WxuMedalliaSurvey: {
    clientLoader: () => import(/* webpackChunkName: "MedalliaSurvey" */'@wxu/modules/src/MedalliaSurvey/client.loader').then(module => module.clientLoader),
  },
  WxuTodayMapCard: {
    clientLoader: () => import(/* webpackChunkName: "TodayMapCard" */'@wxu/modules/src/TodayMapCard/client.loader').then(module => module.clientLoader),
  },
  WxuPromoDriver: {
    clientLoader: () => import(/* webpackChunkName: "PromoDriver" */'@wxu/modules/src/PromoDriver/client.loader').then(module => module.clientLoader),
  },
  // LARGE SCREEN SPECIFIC MODULES
  WxuFooter: {
    clientLoader: () => import(/* webpackChunkName: "Footer" */'@wxu/modules/src/Footer/client.loader').then(module => module.clientLoader),
  },
  WxuLocalsuiteNav: {
    clientLoader: () => import(/* webpackChunkName: "LocalsuiteNav" */'@wxu/modules/src/LocalsuiteNav/client.loader').then(module => module.clientLoader),
  },
  WxuHeaderLargeScreen: {
    clientLoader: () => import(/* webpackChunkName: "HeaderLargeScreen" */'@wxu/modules/src/HeaderLargeScreen/client.loader').then(module => module.clientLoader),
  },
  WxuSavedLocations: {
    clientLoader: () => import(/* webpackChunkName: "SavedLocations" */'@wxu/modules/src/SavedLocations/client.loader').then(module => module.clientLoader),
  },
  WxuSmartMessage: {
    clientLoader: () => import(/* webpackChunkName: "SmartMessage" */'@wxu/modules/src/SmartMessage/client.loader').then(module => module.clientLoader),
  },
  WxuSurveyCard: {
    clientLoader: () => import(/* webpackChunkName: "SurveyCard" */'@wxu/modules/src/SurveyCard/client.loader').then(module => module.clientLoader),
  },
  WxuPollCard: {
    clientLoader: () => import(/* webpackChunkName: "InsightBentoCard" */'@wxu/modules/src/Poll/client.loader').then(module => module.clientLoader),
  },
  WxuMobileWebBanner: {
    clientLoader: () => import(/* webpackChunkName: "MobileWebBanner" */'@wxu/modules/src/MobileWebBanner/client.loader').then(module => module.clientLoader),
  },
  WxuWatsonMomentsFluInsights: {
    clientLoader: () => import(/* webpackChunkName: "WatsonMomentsFluInsights" */'@wxu/modules/src/WatsonMomentsFluInsights/client.loader').then(module => module.clientLoader),
  },
  WxuPrivacyConsentModal: {
    clientLoader: () => import(/* webpackChunkName: "WxuPrivacyConsentModal" */'@wxu/modules/src/PrivacyConsentModal/client.loader').then(module => module.clientLoader),
  },
  WxuHurricaneTrackerCard: {
    clientLoader: () => import(/* webpackChunkName: "HurricaneTrackerCard" */'@wxu/modules/src/HurricaneTrackerCard/client.loader').then(module => module.clientLoader),
  },
  AnonymousNewsletter: {
    clientLoader: () => import(/* webpackChunkName: "AnonymousNewsletter" */'@wxu/modules/src/AnonymousNewsletter/client.loader').then(module => module.clientLoader),
  },
  WxuOpenContentIframe: {
    clientLoader: () => import(/* webpackChunkName: "OpenContentIframe" */'@wxu/modules/src/OpenContentIframe/client.loader').then(module => module.clientLoader),
  },
  WxuBreakingNewsTicker: {
    clientLoader: () => import(/* webpackChunkName: "BreakingNewsTicker" */'@wxu/modules/src/BreakingNewsTicker/client.loader').then(module => module.clientLoader),
  },
  WxuPuzzmoEmbed: {
    clientLoader: () => import(/* webpackChunkName: "PuzzmoEmbed" */'@wxu/modules/src/PuzzmoEmbed/client.loader').then(module => module.clientLoader),
  },
  // APPEND_MODULE
};
